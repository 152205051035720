import { Form, Formik } from "formik"
import React, { Fragment, useContext } from "react"

import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"

import { generateFormField } from "elements/Form/services/form.js"
import { AppContext } from "../../../context/AppContext"

const EnrollmentConsent = ({
  sectionFormFields,
  validationSchema,
  handleSubmit,
  pageContext,
  loading,
}) => {
  const { state } = useContext(AppContext)
  const { enrollment } = state

  return (
    <Formik
      initialValues={{ ...enrollment }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors, isValid }) => (
        <Form>
          {sectionFormFields.map((section) => (
            <Section title={section?.section}>
              {section?.fields.map((field) => {
                if (!field?.referenceAnswer) {
                  return (
                    <Fragment>
                      {generateFormField({
                        formFields: section?.fields,
                        formField: field,
                        values,
                        setFieldValue,
                      })}
                      {!!field?.addDividerAfterField && (
                        <hr className="has-background-light" />
                      )}
                    </Fragment>
                  )
                }
                return null
              })}
            </Section>
          ))}
          <ActionButtons
            back={{ label: "Back", link: pageContext.backPath }}
            submit={{
              label: "Submit",
              loading,
              disabled:
                !values?.consentAndAuthorization ||
                values?.consentAndAuthorization?.length < 2,
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default EnrollmentConsent
