export const checkIfAddressIsUnique = (addressToAdd, addressesList) => {
  return !addressesList.find((address) => {
    return (
      // Address type
      address.addressType?.toLowerCase() ===
        addressToAdd.addressType?.toLowerCase() &&
      // Street address
      address.streetAddress?.toLowerCase() ===
        addressToAdd.streetAddress?.toLowerCase() &&
      // City
      address.city.value === addressToAdd.city.value &&
      // Province
      address.province.value === addressToAdd.province.value
    )
  })
}
